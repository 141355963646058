import { GifID, IChannel, IGif } from '@giphy/js-types'
import { getFirstPathSegment, getPageOptionsBasedOnPath, PageOptions } from './utils/page'
import { getSiteOptions, SiteOptions } from './utils/site'
import { ShareUrlRenditionType } from 'utils/src/media/sharing'

declare global {
    interface Window {
        user?: UserOptions
        creator?: CreatorOptions
        GTM_CONTAINER_ID: string
        CURRENT_VERSION: string
        dataLayer: any[]
    }
}

export interface ContentOptions {
    contentId: GifID
    contentType: string
    contentFormat?: string // File format, for GIF share only
    contentRendition?: ShareUrlRenditionType // Renditon, for GIF share only
}

export interface CreatorOptions {
    creatorName?: string
    creatorId?: string
}

export interface UserOptions {
    loggedInStatus: boolean
    userType: 'user' | 'staff' | 'artist' | 'partner' | 'anonymous'
    verifiedStatus?: boolean
    userID?: string
    loginMethod?: string
}

export interface GAEventData {
    event?: string
    site?: SiteOptions
    page?: PageOptions
    user?: UserOptions
    creator?: CreatorOptions
    [key: string]: any
}

export const setCreator = (creator: CreatorOptions) => {
    if (typeof window !== 'undefined') {
        window.creator = creator
    }
}

export const getCreator = (): CreatorOptions => {
    if (window.creator) return window.creator

    return {
        creatorId: '',
        creatorName: '',
    }
}

export const getContentOfGif = (
    gif: IGif,
    params: {
        format?: ContentOptions['contentFormat']
        rendition?: ContentOptions['contentRendition']
    } = {}
): ContentOptions => {
    let data = {
        contentId: gif.id,
        contentType: gif.is_sticker ? 'sticker' : gif.type,
    } as ContentOptions

    if (params.format) {
        data.contentFormat = params.format
    }
    if (params.rendition) {
        data.contentRendition = params.rendition
    }

    return data
}

export const getCreatorOfGif = (gif: IGif) => ({
    creatorName: gif?.user?.display_name || gif?.user?.username,
    creatorId: gif?.user?.id,
})

export const getChannel = (channel: IChannel) => ({
    channelTitle: channel.display_name,
    channel_id: channel.id,
})

export const getUserChannel = (user: { channel?: string; channel_id?: number }) => {
    if (!user) return {}
    return {
        channelTitle: user.channel,
        channel_id: user.channel_id,
    }
}

export const setUser = (user: UserOptions) => {
    window.user = user
}

export const getUser = (): UserOptions => {
    if (window.user) return window.user

    return {
        loggedInStatus: false,
        userType: 'anonymous',
        verifiedStatus: false,
        userID: '',
        loginMethod: '',
    }
}

export const getInitialGADataLayerData = (): GAEventData => ({
    user: { ...getUser() },
    creator: { ...getCreator() },
    site: { ...getSiteOptions() },
    page: { ...getPageOptionsBasedOnPath(getFirstPathSegment(window.location.href)) },
})

export const getGADataLayerData = (): GAEventData => ({
    creator: { ...getCreator() },
    site: { ...getSiteOptions() },
    page: { ...getPageOptionsBasedOnPath(getFirstPathSegment(window.location.href)) },
})

export const setGADataLayer = (params?: { event?: string; options?: { [key: string]: any } }) => {
    if (!window.dataLayer) window.dataLayer = []

    const data = getGADataLayerData()

    if (params?.event) {
        data.event = params.event
    }

    window.dataLayer.push({
        ...data,
        ...params?.options,
    })
}

export const trackGA4NavigationEvent = (label: string, path: string, parent: string) => {
    const data = {
        event: 'menu_nav',
        options: {
            eventInfo: {
                navElement: parent,
                navSubelement: label,
                link: new URL(path, location.origin).href,
            },
        },
    }
    setGADataLayer(data)
}

export const getUserLoginParameters = () => {
    if (typeof window == 'undefined') {
        return {
            platform: undefined,
            action: undefined,
        }
    }

    const queryParams = new URLSearchParams(window?.location?.search)
    const loginType = queryParams.get('loginType')

    let platform: string | undefined = undefined
    let action: string | undefined = undefined

    if (loginType) {
        const [platformName, actionType] = loginType.split('_')
        platform = platformName
        action = actionType
    }

    return {
        platform,
        action,
    }
}

export const getUrlFileType = (url: string): string | undefined => {
    const u = new URL(url)
    const ext = u.pathname.split('.').pop()
    return ext === '/' || !ext ? undefined : ext.toLowerCase()
}
